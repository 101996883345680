import React, { useState, useEffect } from 'react';

function CategoryItem({ category }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="border-b border-gray-200">
      <div className="flex justify-between items-center p-4 cursor-pointer" onClick={toggleOpen}>
        <a href={`/alquiler/${category.slug}`}>{category.description}</a>
        {category.categories && category.categories.length > 0 && (
          <span>{isOpen ? '-' : '+'}</span>
        )}
      </div>
      {isOpen && category.categories && (
        <div className="pl-4 bg-[#F4F4F4] rounded-lg">
          {category.categories.map((subCategory) => (
            <CategoryItem key={subCategory.id} category={subCategory} />
          ))}
        </div>
      )}
    </div>
  );
}

function MobileCollapseCategories({ allCategories }) {
  useEffect(() => {
    console.log(allCategories);
  }, [allCategories]);

  return (
    <div className="w-full max-w-md mx-auto bg-white rounded-lg">
      {allCategories.data.map((category) => (
        <CategoryItem key={category.id} category={category} />
      ))}
    </div>
  );
}

export default MobileCollapseCategories;