import React, { useEffect, useState } from "react";
import Menu from "../../components/private/Menu";
import { useAuth } from "../../hooks/AuthProvider";
import Button from "../../components/ui/Button";
import { useError } from "../../helpers/ErrorContext";

const Account = () => {
	const [errors, setErrors] = useState({});
	const [errorsMessages, setErrorsMessages] = useState({});
	const { token, isAuthenticated } = useAuth();
	const { setError } = useError();
	const [userData, setUserData] = useState({
		name: "",
		surname: "",
		email: "",
		phone: "",
		address: { address: '', town: '', zip: '', province: '' },
		vat: "",
		legal_form: "",
		country_id: "212",
		customer_type: "individual",
	});
	const [successMessage, setSuccessMessage] = useState('');

	function validateInputs(inputs) {
		let errors = {};
		if (!inputs.name) {
			errors.name = 'El nombre es obligatorio.';
		}
		if (!inputs.surname) {
			errors.surname = 'El apellido es obligatorio.';
		}
		if (!inputs.phone) {
			errors.phone = 'El teléfono es obligatorio.';
		} else if (!/^\d+$/.test(inputs.phone)) {
			errors.phone = 'El teléfono solo puede contener números.';
		}
		if (!inputs.email) {
			errors.email = 'El email es obligatorio.';
		} else if (!/\S+@\S+\.\S+/.test(inputs.email)) {
			errors.email = 'Email no válido.';
		}
		if (!inputs.address.address) {
			errors.address = 'Indique una dirección.';
		}
		if (!inputs.address.town) {
			errors.town = 'Indique una población.';
		}
		if (!inputs.address.zip) {
			errors.zip = 'Indique un código postal.';
		}
		if (!inputs.address.province) {
			errors.province = 'Indique una provincia.';
		}
		if (!inputs.vat) {
			errors.vat = 'Indique el CIF/NIF/NIE.';
		}
		if (!inputs.legal_form) {
			errors.legal_form = 'Indique la razón social.';
		}
		return errors;
	}

	const urlParams = [
		["with[0]", "favorite_products"],
		["with[1]", "thirdparty.fiscal_address"],
		["with[2]", "thirdparty.addresses"],
	];

	const paramString = urlParams.map(([param, value]) => `${param}=${value}`).join("&");
	const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

	useEffect(() => {
		const fetchUserData = async () => {
			try {
				const response = await fetch(`${API_BASE_URL}/user?${paramString}`, {
					method: "GET",
					headers: {
						"Accept": "application/json",
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				});
				const userData = await response.json();
				if (!response.ok) {
						const errorMessages = Object.values(userData.errors)
										.flat()
										.join(', ');
						throw new Error(errorMessages);
				}
				const { name, surname, email, phone, thirdparty } = userData;
				const address = thirdparty && thirdparty.fiscal_address ? thirdparty.fiscal_address : { address: '', town: '', zip: '', province: '' };
				setUserData({
					name,
					surname,
					email,
					phone: thirdparty?.phone || "",
					address,
					vat: thirdparty?.vat || "",
					legal_form: thirdparty?.legal_form || "",
					country_id: "212",
					customer_type: thirdparty?.thirdparty_type || "individual",
				});
			} catch (error) {
				// setErrorsMessages({ general: error.message });
				setError(error);
			}
		};

		if (isAuthenticated) {
			fetchUserData();
		}
	}, [isAuthenticated, token]);

	const handleChange = (e) => {
		const { name, value, type, checked } = e.target;
		setUserData((prevData) => ({
			...prevData,
			[name]: type === "checkbox" ? checked : value,
			address: {
				...prevData.address,
				[name]: name in prevData.address ? value : prevData.address[name],
			},
		}));
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setSuccessMessage('');
		const validationErrors = validateInputs(userData);
		if (Object.keys(validationErrors).length > 0) {
			setErrors(validationErrors);
		} else {
			setErrors({});
			try {
				const requestData = {
					vat: userData.vat,
					legal_form: userData.legal_form,
					address: userData.address.address,
					town: userData.address.town,
					province: userData.address.province,
					zip: userData.address.zip,
					country_id: userData.country_id,
					customer_type: userData.customer_type,
					phone: userData.phone,
				};


				const response = await fetch(`${API_BASE_URL}/register-customer`, {
					method: "POST",
					headers: {
						"Accept": "application/json",
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify(requestData),
				});

				const responseData = await response.json();  // Obtener datos de respuesta

				if (!response.ok) {
					const errorMessages = Object.values(responseData.errors)
							.flat()
							.join(', ');
					throw new Error(errorMessages);
			}

				setUserData({
					...userData,
					vat: responseData.vat,
					legal_form: responseData.legal_form,
					address: {
						address: responseData.address,
						town: responseData.town,
						zip: responseData.zip,
						province: responseData.province,
					},
				});
				setSuccessMessage('Cliente registrado correctamente.');
				setErrorsMessages({});
			} catch (error) {
				// setErrorsMessages({ general: error.message });
				setError(error);
			}
		}
	};

	return (
		<div className="p-[15px] md:p-[35px] flex flex-col md:flex-row gap-8 md:gap-24 min-h-[calc(100vh-135px)]">
			<Menu position="account" />
			<div className="w-full">
				<h2 className="mb-2 text-3xl font-bold text-left text-primary">mi cuenta</h2>
				<form onSubmit={handleSubmit} className="flex flex-col w-full h-full gap-4 px-4 py-8 md:px-8 md:py-8 text-left bg-white rounded-xl">
					<div className="flex flex-col md:flex-row gap-6">
						<div className="w-full md:w-1/2">
							<label htmlFor="name" className="pl-[25px]">Nombre *</label>
							<input
								type="text"
								name="name"
								id="name"
								value={userData.name}
								onChange={handleChange}
								className="w-full px-6 py-4 mt-2 transition-all duration-150 ease-in-out rounded-full bg-light"
								placeholder="introduzca el nombre" />
						</div>
						<div className="w-full md:w-1/2">
							<label htmlFor="surname" className="pl-[25px]">Apellidos *</label>
							<input
								type="text"
								id="surname"
								name="surname"
								value={userData.surname}
								onChange={handleChange}
								className="w-full px-6 py-4 mt-2 transition-all duration-150 ease-in-out rounded-full bg-light"
								placeholder="introduzca el apellido" />
						</div>
					</div>
					<div className="flex flex-col md:flex-row gap-4">
						<div className="w-full md:w-3/5">
							<label htmlFor="email" className="pl-[25px]">Email *</label>
							<input
								type="text"
								id="email"
								name="email"
								value={userData.email}
								onChange={handleChange}
								className="w-full px-6 py-4 mt-2 transition-all duration-150 ease-in-out rounded-full bg-light"
								placeholder="correo eléctronico" />
						</div>
						<div className="w-full md:w-2/5">
							<label htmlFor="phone" className="pl-[25px]">Teléfono *</label>
							<input
								type="text"
								id="phone"
								name="phone"
								value={userData.phone}
								onChange={handleChange}
								className="w-full px-6 py-4 mt-2 md:text-center transition-all duration-150 ease-in-out rounded-full bg-light"
								placeholder="nº de telefono"
							/>
						</div>
					</div>
					{/* <div className="flex flex-col w-full gap-1 mb-5 md:gap-0 md:justify-between md:flex-row">
						<div className="flex gap-3">
							<input type="radio" name="customer_type" value="individual" onChange={handleChange} id="customer_type_1" checked={userData.customer_type === 'individual'} />
							<label className="text-lg cursor-pointer" htmlFor="customer_type_1">Soy particular</label>
						</div>
						<div className="flex gap-3">
							<input type="radio" name="customer_type" value="bussines" onChange={handleChange} id="customer_type_2" checked={userData.customer_type === 'bussines'} />
							<label className="text-lg cursor-pointer" htmlFor="customer_type_2">Soy autonómo</label>
						</div>
						<div className="flex gap-3">
							<input type="radio" name="customer_type" value="company" onChange={handleChange} id="customer_type_3" checked={userData.customer_type === 'company'} />
							<label className="text-lg cursor-pointer" htmlFor="customer_type_3">Soy empresa</label>
						</div>
					</div> */}
					<div className="mt-5 font-bold text-left text-primary-500">Dirección fiscal</div>
					<div className="flex flex-col gap-[20px]">
						<div className="flex flex-col md:flex-row gap-6">
							<div className="w-full md:w-3/5">
								<label htmlFor="address" className="pl-[25px]">Dirección *</label>
								<input
									type="text"
									id="address"
									name="address"
									value={userData.address.address}
									onChange={handleChange}
									className="w-full px-6 py-4 mt-2 transition-all duration-150 ease-in-out rounded-full bg-light"
									placeholder="introduzca su dirección"
								/>
							</div>
							<div className="w-full md:w-2/5">
								<label htmlFor="province" className="pl-[25px]">Provincia *</label>
								<input
									type="text"
									id="province"
									name="province"
									value={userData.address.province}
									onChange={handleChange}
									className="w-full px-6 py-4 mt-2 transition-all duration-150 ease-in-out rounded-full bg-light"
									placeholder=""
								/>
							</div>
						</div>
						<div className="flex flex-col md:flex-row gap-6">
							<div className="w-full md:w-1/5">
								<label htmlFor="zip" className="pl-[25px]">Código postal *</label>
								<input
									type="text"
									id="zip"
									name="zip"
									value={userData.address.zip}
									onChange={handleChange}
									className="w-full px-6 py-4 mt-2 text-center transition-all duration-150 ease-in-out rounded-full bg-light"
									placeholder=""
								/>
							</div>
							<div className="w-full md:w-4/5">
								<label htmlFor="town" className="pl-[25px]">Población *</label>
								<input
									type="text"
									id="town"
									name="town"
									value={userData.address.town}
									onChange={handleChange}
									className="w-full px-6 py-4 mt-2 transition-all duration-150 ease-in-out rounded-full bg-light"
									placeholder=""
								/>
							</div>
						</div>
						<div className="flex flex-col md:flex-row gap-6">
							<div className="w-full md:w-1/2">
								<label htmlFor="vat" className="pl-[25px]">CIF/NIF/NIE *</label>
								<input
									type="text"
									id="vat"
									name="vat"
									value={userData.vat}
									onChange={handleChange}
									className="w-full px-6 py-4 mt-2 transition-all duration-150 ease-in-out rounded-full bg-light"
									placeholder="CIF/NIF/NIE"
								/>
							</div>
							<div className="w-full md:w-1/2">
								<label htmlFor="legal_form" className="pl-[25px]">Razón Social *</label>
								<input
									type="text"
									id="legal_form"
									name="legal_form"
									value={userData.legal_form}
									onChange={handleChange}
									className="w-full px-6 py-4 mt-2 transition-all duration-150 ease-in-out rounded-full bg-light"
									placeholder="Razón Social"
								/>
							</div>
						</div>
					</div>
					<div className="flex justify-start">
						<Button type="primary" classButton="mt-4" onClick={handleSubmit}>
							Guardar
						</Button>
					</div>
					{Object.keys(errors).length > 0 && (
						<div>
							{Object.entries(errors).map(([key, value], index) => (
								<p key={index} className="w-full text-xs text-center text-red-500">{value}</p>
							))}
						</div>
					)}
					{Object.keys(errorsMessages).length > 0 && (
						<div>
							{Object.entries(errorsMessages).map(([key, value], index) => (
								<p key={index} className="w-full text-xs text-center text-red-500">{value}</p>
							))}
						</div>
					)}
					{successMessage && (
						<div className="w-full text-xs text-center text-green-500">{successMessage}</div>
					)}
				</form>
			</div>
		</div>
	);
}

export default Account;
